import {
  httpApps,
  httpAdminCompanyLocation,
  httpDealer,
} from "../helper/http-common";

class SelectYourDiamoiondServices {
  GetRingBuilderOpeningPage(inputData) {
    return httpApps.post(
      "PendantBuilderForML/GetPendentBuilderOpeningPage",
      inputData
    );
  }
  PendantBuilderLoadDiamonds(inputData) {
    return httpApps.post(
      "PendantBuilderForML/PendantBuilderLoadDiamonds",
      inputData
    );
  }
  LoadCompareDiamondsList(inputData) {
    return httpApps.post(
      "PendantBuilderForML/LoadCompareDiamondsList",
      inputData
    );
  }

  PendantBuilderLabGrownDiamonds(inputData) {
    return httpApps.post(
      "PendantBuilderForML/PendantBuilderLabGrownDiamonds",
      inputData
    );
  }

  GetAvailablePendantbuilderFilters(inputData) {
    return httpApps.post(
      "PendantBuilderForML/GetAvailablePendantbuilderFilters",
      inputData
    );
  }

  SendMailToRetailerForNoResults(inputData) {
    return httpApps.post(
      "PendantBuilderForML/SendMailToRetailerForNoResults",
      inputData
    );
  }
  GetRingBuilderMinedCompareData(inputData) {
    return httpApps.post(
      "RingBuilderForML/GetRingBuilderMinedCompareData",
      inputData
    );
  }

  GetRingBuilderLabCompareData(inputData) {
    return httpApps.post(
      "RingBuilderForML/GetRingBuilderLabCompareData",
      inputData
    );
  }

  RingBuilderRequestCertificate(inputData) {
    return httpApps.post(
      "RingBuilderForML/RingBuilderRequestCertificate",
      inputData
    );
  }

  GetDealerInformation(inputData) {
    return httpApps.post("DiamondLinkForML/GetDealerInformation", inputData);
  }

  GetSimilarDiamondsByPage(inputData, pageName) {
    if (pageName == "Mined") {
      return httpApps.post(
        "PendantBuilderForML/PendantBuilderLoadDiamonds",
        inputData
      );
    } else {
      return httpApps.post(
        "PendantBuilderForML/PendantBuilderLabGrownDiamonds",
        inputData
      );
    }
  }

  GetRingBuilderViewDetailsMineLabDiamond(inputData) {
    return httpApps.post(
      "PendantBuilderForML/PendantBuilderGetData",
      inputData
    );
  }

  GetDealerLocationData(inputData) {
    return httpApps.post("DiamondLinkForML/GetDealerLocationData", inputData);
  }
  GetsPendantBuilderOptiondetails(inputData) {
    return httpApps.post(
      "PendantBuilderForML/GetsPendantBuilderOptiondetails",
      inputData
    );
  }
  GetLocationDetails(inputData) {
    return httpAdminCompanyLocation.post(
      "/Profile/LocationLoadDetailsForML",
      inputData
    );
  }
  ProductTracking(inputData) {
    return httpApps.post("DiamondLinkForML/ProductTracking", inputData);
  }
  DiamondTracking(inputData) {
    return httpApps.post("DiamondLinkForML/DiamondTracking", inputData);
  }
  GetMenuList(inputData) {
    return httpAdminCompanyLocation.post("/Tools/GetMenuListForML", inputData);
  }
  CompanyWhileLabel() {
    return httpDealer.get("/Dashboard/CompanyWhileLabelForML");
  }
  GetLanguage(inputData) {
    return httpAdminCompanyLocation.post(
      "/SelectLenguage/SelectlanguageForDelarForML",
      inputData
    );
  }
}

export default new SelectYourDiamoiondServices();
