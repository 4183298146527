import React, { useEffect ,useState} from "react";
import {  Route, Routes, Outlet,useLocation } from "react-router-dom";
import ChooseYourSetting from "./component/ApplicationPreview/ChooseYourSetting";
import SelectYourDiamond from "./component/ApplicationPreview/SelectYourDiamond";
import CompleteYourRing from "./component/ApplicationPreview/CompleteYourRing";
import Layout from "./component/Layout";
// import ChooseSettingItemDetails from "./component/ApplicationPreview/ChooseSettingItemDetails";
import Compare from "./component/common/SelectYourDiamond/Compare";
import Request from "./component/common/SelectYourDiamond/Request";
import ColorPanel from "./component/ColorPanel";
import MinedDiamond from "./component/common/SelectYourDiamond/MinedDiamond";
import LabGrown from "./component/common/SelectYourDiamond/LabGrown";
import ViewDiamondDetails from "./component/common/SelectYourDiamond/ViewDiamondDetails";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import i18n from "./i18n";
import SelectYourDiamoiondServices from "./services/select-your-diamond.service";


const PrivateRoute = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

const App = () => {
  const search = useLocation().search;
  const fontStyle = useSelector(
    (state) => state.previewColorReducer.fontFamily
  );
  const [cookies] = useCookies(["lngCookie"]);

 const [language, setLanguage] = useState("English");
 const paramdealerId = new URLSearchParams(search).get("DealerLink");  useEffect(() => {
    if (language) {
      /// added language check conditions
      let tempLanguage =
        language === "English" || language === "en"
          ? "en"
          : language === "Spanish" || language === "es"
          ? "es"
          : language === "Arabic" || language === "ar"
          ? "ar"
          : "en";
      i18n.changeLanguage(tempLanguage);
    }
  }, [language, i18n]);

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty(
      "--dynamic-font-family",
      fontStyle || "LexendDeca-Regular"
    );
  }, [fontStyle]);

    useEffect(() => {
      handleLanguage(paramdealerId);
    }, [paramdealerId]);
    const languageCodes = {
      Spanish: "es",
      English: "en",
      Arabic: "ar",
    };
    const handleLanguage = (id) => {
      debugger;
      let inputData = {
        delarId: id,
      };
      SelectYourDiamoiondServices.GetLanguage(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setLanguage(languageCodes[responseData.language] || null);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

  return (
    <div style={{ fontFamily: fontStyle ? fontStyle : "LexendDeca-Regular" }}>
      <React.Fragment>
        <Routes>
          <Route path="/selectYourDiamond" element={<SelectYourDiamond />} />
          <Route exact path="/" element={<PrivateRoute />}>
            <Route path="/Mined" element={<MinedDiamond />} />
            <Route path="/Lab" element={<LabGrown />} />
            {/* <Route path="/Fancy" element={<FancyTab />} /> */}

            <Route path="/Request" element={<Request />} />
            <Route path="/Compare" element={<Compare />} />
            <Route path="/completeyourring" element={<CompleteYourRing />} />
            <Route
              path="/viewDiamondDetails"
              element={<ViewDiamondDetails />}
            />
            <Route path="/chooseYourSetting" element={<ChooseYourSetting />} />
          </Route>
        </Routes>
        <ColorPanel></ColorPanel>
      </React.Fragment>
    </div>
  );
};

export default App;
